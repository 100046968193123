import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_EMPLEADOS

export default class CargosService {
    get () {
        return http.get(`${baseUrl}/cargos/listar-cargos`, {
            headers: {
                loading: true
            }
        })
    }

    put (params) {
      return http.put(`${baseUrl}/cargos/editar-cargos`, params, {
        headers: {
          loading: true
        }
      })
    }

    post (params) {
      return http.post(`${baseUrl}/cargos/crear-cargos`, params, {
        headers: {
          loading: true
        }
      })
    }

    find (params) {
      return http.get(`${baseUrl}/cargos/obtener-cargo`, {
        params,
        headers: {
          loading: true
        }
      })
    }
}
