import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_EMPLEADOS

export default class AreasService {
    get () {
        return http.get(`${baseUrl}/areas/listar-areas`, {
            headers: {
                loading: true
            }
        })
    }

    find (params) {
        return http.get(`${baseUrl}/areas/obtener-area`, {
            params,
            headers: {
                loading: true
            }
        })
    }

  getEncargadosArea () {
    return http.get(`${baseUrl}/areas/get-encargado`, {
      headers: {
        loading: true
      }
    })
  }

  postArea (payload) {
    return http.post(`${baseUrl}/areas/post-area`, payload, {
      headers: {
        loading: true
      }
    })
  }

  putArea ({ data, id }) {
    return http.put(`${baseUrl}/areas/put-area/${id}`, data, {
      headers: {
        loading: true
      }
    })
  }

  getAreasPrincipales () {
    return http.get(`${baseUrl}/areas/get-areas-principales`, {
      headers: {
        loading: true
      }
    })
  }

  getAreaById (id) {
    return http.get(`${baseUrl}/areas/get-area-by-id/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
