<template>
  <section>
    <div class="w-full bg-white mt-4 p-4">
      <div class="w-full grid grid-cols-2">
        <div>
          <p class="text-blue-800 text-lg font-bold">
            Cargos
          </p>
        </div>
        <div class="flex justify-end">
          <Button @click="$router.push({ name: 'pharmasan.recursos-humanos.configuracion.cargos.crear-cargo' })" type="button" label="Crear cargo" class="mx-2" icon="pi pi-sitemap" />
          <!-- <Button type="button" class="p-button-warning" raised  label="Crear documento" icon="pi pi-file" /> -->
        </div>
      </div>
      <div class="w-full mt-4">
        <DataTable paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]" :value="listadoCargos" tableStyle="min-width: 50rem">
          <Column headerClass="text-xs" bodyClass="text-xs" field="name" header="Nombre"></Column>
          <Column headerClass="text-xs" bodyClass="text-xs" field="descripcion_cargo" header="Descripción"></Column>
          <Column headerClass="text-xs" bodyClass="text-xs" field="salario" header="Salario"></Column>
          <Column headerClass="text-xs" bodyClass="text-xs" field="nombre_area" header="Area/Sub-area"></Column>
          <Column headerClass="text-xs" bodyClass="text-xs" header="Acciones">
              <template #body="slotProps">
                <Button icon="pi pi-pencil" @click="$router.push({ name: 'pharmasan.recursos-humanos.configuracion.cargos.editar-cargo', params: { id: slotProps.data.id } })" class="p-button-rounded p-button-text" aria-label="Editar" />
              </template>
          </Column>
        </DataTable>
        <Dialog :closable="false" header="Editar cargo" v-model:visible="displayEditarCargo" :style="{width: '30vw'}" :modal="true">
          <div class="w-full my-1">
            <label for="name" class="text-sm text-gray-600">Nombre</label>
            <InputText class="w-full" type="text" id="name" v-model="dataCargo.name" />
          </div>
          <div class="w-full my-1">
            <label for="salario" class="text-sm text-gray-600">Salario</label>
            <InputNumber class="w-full" id="salario" v-model="dataCargo.salario" mode="decimal" :minFractionDigits="2" />
          </div>
          <div class="w-full my-1">
            <label for="descripcion_cargo" class="text-sm text-gray-600">Descripción del cargo</label>
            <Textarea v-model="dataCargo.descripcion_cargo" class="w-full" type="text" id="descripcion_cargo" rows="5" cols="30" />
          </div>
          <div class="w-full my-1">
            <label for="descripcion_cargo" class="text-sm text-gray-600">Área</label>
            <Dropdown class="w-full border-gray-300 rounded-md" v-model="dataCargo.area_id" :options="listadoAreas" optionLabel="name" optionValue="id" :filter="true" placeholder="Seleccione" :showClear="true">
            <template #value="slotProps">
                <span v-if="slotProps.value">
                  {{listadoAreas.find(a => a.id === slotProps.value).parent_area_id ? 'Sub area - ' : 'Area - '}}{{listadoAreas.find(a => a.id === slotProps.value).name}}
                </span>
                <span v-else>
                    {{slotProps.placeholder}}
                </span>
            </template>
            <template #option="slotProps">
                <div>
                  {{slotProps.option.parent_area_id ? 'Sub area - ' : 'Area - '}}{{slotProps.option.name}}
                </div>
            </template>
        </Dropdown>
          </div>
          <div class="w-full my-1 flex items-center gap-2 mt-4">
            <label for="descripcion_cargo" class="text-sm text-gray-600">Encargado de area</label>
            <InputSwitch v-model="dataCargo.jefe_area" />
          </div>
          <template #footer>
              <Button label="Cancelar" icon="pi pi-times" @click="editarCargo(1)" class="p-button-text"/>
              <Button label="Editar" icon="pi pi-check" @click="editarCargo(2)" autofocus />
          </template>
        </Dialog>
        <Dialog :closable="false" header="Crear cargo" v-model:visible="displayCrearCargo" :style="{width: '30vw'}" :modal="true">
          <div class="w-full my-1">
            <label for="name" class="text-sm text-gray-600">Nombre</label>
            <InputText class="w-full" type="text" id="name" v-model="dataCargo.name" />
          </div>
          <div class="w-full my-1">
            <label for="salario" class="text-sm text-gray-600">Salario</label>
            <InputNumber class="w-full" id="salario" v-model="dataCargo.salario" mode="decimal" :minFractionDigits="2" />
          </div>
          <div class="w-full my-1">
            <label for="descripcion_cargo" class="text-sm text-gray-600">Descripción del cargo</label>
            <Textarea v-model="dataCargo.descripcion_cargo" class="w-full" type="text" id="descripcion_cargo" rows="5" cols="30" />
          </div>
          <div class="w-full my-1">
            <label for="descripcion_cargo" class="text-sm text-gray-600">Área</label>
            <Dropdown class="w-full border-gray-300 rounded-md" v-model="dataCargo.area_id" :options="listadoAreas" optionLabel="nombre_padre" optionValue="id" :filter="true" placeholder="Seleccione" :showClear="true"></Dropdown>
          </div>
          <div class="w-full my-1 flex items-center gap-2 mt-4">
            <label for="descripcion_cargo" class="text-sm text-gray-600">Encargado de area</label>
            <InputSwitch v-model="dataCargo.jefe_area" />
          </div>
          <template #footer>
              <Button label="Cancelar" icon="pi pi-times" @click="crearCargo(1)" class="p-button-text"/>
              <Button label="Editar" icon="pi pi-check" @click="crearCargo(2)" autofocus />
          </template>
        </Dialog>
      </div>
    </div>
  </section>
</template>
<script>
  import CargosService from '../../../../services_back_new/cargos.service'
  import AreasService from '../../../../services_back_new/areas.service'
  import Swal from 'sweetalert2'
  import dayjs from 'dayjs'
  import { ref, onMounted } from 'vue'
  export default {
    name: 'cargos',
    setup () {
      // Services
      const _CargosService = ref(new CargosService())
      const _AreasService = ref(new AreasService())
      // References
      const listadoCargos = ref([])
      const listadoAreas = ref([])
      const dataCargo = ref({
        name: '',
        descripcion_cargo: '',
        area_id: 0,
        jefe_area: false,
        updatedAt: dayjs(),
        salario: ''
      })
      const displayEditarCargo = ref(false)
      const displayCrearCargo = ref(false)
      // Methods
      const obtenerCargos = () => {
        _CargosService.value.get().then(({ data }) => {
          for (const i of data) {
            listadoCargos.value.push({
              ...i,
              nombre_area: i.area_id ? listadoAreas.value.find(a => a.id === i.area_id).name : ''
            })
          }
        })
      }
      const obtenerAreas = () => {
        _AreasService.value.get().then(({ data }) => {
          for (const i of data) {
            listadoAreas.value.push(
              {
                ...i,
                nombre_padre: i.parent_area_id ? data.find(a => a.id === i.parent_area_id).name + ' - ' + i.name : i.name
              }
            )
          }
          obtenerCargos()
        })
      }
      const abrirModalCargo = (data) => {
        dataCargo.value = data
        displayEditarCargo.value = true
      }
      const editarCargo = (tipo) => {
        if (tipo === 1) {
          dataCargo.value = {
            name: '',
            descripcion_cargo: '',
            area_id: 0,
            jefe_area: false,
            updatedAt: dayjs(),
            salario: ''
          }
          displayEditarCargo.value = false
        } else {
          _CargosService.value.put(dataCargo.value).then(({ data }) => {
            if (data.error) {
              Swal.fire(
                'Error',
                'A ocurrido un error al editar el cargo',
                'error'
              )
            } else {
              Swal.fire(
                'Guardado',
                'Cargo editado con exito',
                'success'
              ).then(() => {
                obtenerCargos()
                dataCargo.value = {
                  name: '',
                  descripcion_cargo: '',
                  area_id: 0,
                  jefe_area: false,
                  updatedAt: dayjs(),
                  salario: ''
                }
                displayEditarCargo.value = false
              })
            }
          })
        }
      }
      const crearCargo = (tipo) => {
        if (tipo === 1) {
          dataCargo.value = {
            name: '',
            descripcion_cargo: '',
            area_id: 0,
            jefe_area: false,
            updatedAt: dayjs(),
            salario: ''
          }
          displayCrearCargo.value = false
        } else {
          _CargosService.value.post(dataCargo.value).then(({ data }) => {
            if (data.error) {
              Swal.fire(
                'Error',
                'A ocurrido un error al editar el cargo',
                'error'
              )
            } else {
              Swal.fire(
                'Guardado',
                'Cargo editado con exito',
                'success'
              ).then(() => {
                obtenerCargos()
                dataCargo.value = {
                  name: '',
                  descripcion_cargo: '',
                  area_id: 0,
                  jefe_area: false,
                  updatedAt: dayjs(),
                  salario: ''
                }
                displayCrearCargo.value = false
              })
            }
          })
        }
      }
      onMounted(() => {
        obtenerAreas()
      })
      return {
        listadoCargos,
        abrirModalCargo,
        dataCargo,
        displayEditarCargo,
        editarCargo,
        listadoAreas,
        displayCrearCargo,
        crearCargo
      }
    }
  }
</script>
